/* eslint-disable no-sparse-arrays */
import React, { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import {
  PUBLIC_ROUTE_TNDS,
  PUBLIC_ROUTE_DOTQUY,
  PUBLIC_ROUTE_PTI_TNDSXEMAY,
  PUBLIC_ROUTE_PTI_TNDSOTO,
  PUBLIC_ROUTE_PTI_VATCHATXEMAY,
  PUBLIC_ROUTE_PTI_BENH_HIEM_NGHEO,
  PUBLIC_ROUTE_PTI_BHSK,
  PUBLIC_ROUTE_LIAN_BHTRUCOT,
  PUBLIC_ROUTE_PVI_HOME_CARE,
  PUBLIC_ROUTE_PVI_TRU_COT,
  PUBLIC_ROUTE_PTI_VUNG_TAM_AN,
  PUBLIC_ROUTE_PAGE_ALL_PRODUCTS,
  PUBLIC_ROUTE_PTI_FINA_BHSUCKHOE,
  PUBLIC_ROUTE_PVI_TRO_CAP_NAM_VIEN,
  PUBLIC_ROUTE_PVI_TAI_NAN_CA_NHAN,
  PUBLIC_ROUTE_MB_BANK_DOWNNLOAD_APP,
  PUBLIC_ROUTE_GRAB_PTI_VUNG_TAM_AN,
  PUBLIC_ROUTE_MIC_TNDSXEMAY,
  PUBLIC_ROUTE_TNDSXEMAY,
  PUBLIC_ROUTE_SELLY_MIC_TNDSXEMAY,
  PUBLIC_ROUTE_MIC_BAO_AN_TINH_DUNG,
  PUBLIC_ROUTE_REGIST_ACC_GSALE,
  PUBLIC_ROUTE_RENEWALS_ORDER_INFO,
  PUBLIC_ROUTE_RENEWALS_ORDER_RESULT,
  PUBLIC_ROUTE_REBUILD_WEB_BYPRODUCTID,
  RETRACK_LOADING,
  PUBLIC_ROUTE_TOMATO_COMBO,
  PUBLIC_ROUTE_PTI_TNDS_OTOV2,
  PUBLIC_ROUTE_EASYCARE,
  PUBLIC_ROUTE_PHUCBINHAN,
  PUBLIC_ROUTE_TNDS_VCX_CAR,
  PUBLIC_ROUTE_PTI_EPASS_TNDSOTO,
  PUBLIC_ROUTE_VNI_EPASS_TNDSOTO,
  PUBLIC_ROUTE_PAGE_ALL_PRODUCTS_EPASS,
  PUBLIC_ROUTE_PAGE_TRAVEL,
  PUBLIC_ROUTE_SALE_TNDS_VCX_CAR,
  PUBLIC_ROUTE_SALE_TNDS_VCX_CAR_RESULT,
  PUBLIC_ROUTE_PAGE_FAMILY_CARE,
  PUBLIC_ROUTE_PVI_BENHHIEMNGHEO,
  PUBLIC_ROUTE_VNI_TNDSOTO,
  PUBLIC_ROUTE_TOMATO_GSALE,
  PUBLIC_ROUTE_SELLY_MIC_TNDSXEMAY_ALL,
  PUBLIC_ROUTE_MIC_BUSINESS_TRAVEL,
  PUBLIC_ROUTE_MIC_TNDS_VCX_CAR,
  PUBLIC_ROUTE_BAO_HIEM_Y_TE,
  PUBLIC_ROUTE_LUCKY_SPIN,
  PUBLIC_ROUTE_LALAMOVE_V2_TNDS_OTO,
  PUBLIC_ROUTE_LALAMOVE_V2_TNDS_XEMAY,
  PUBLIC_ROUTE_LALAMOVE_PTI_VATCHATXEMAY,
  PUBLIC_ROUTE_BM_CARE,
  PUBLIC_ROUTE_MASTER_RIDER,
  PUBLIC_ROUTE_EPASS_V2_TNDS_XEMAY,
  PUBLIC_ROUTE_EPASS_V2_TNDS_OTO,
  PUBLIC_ROUTE_VNI_VCX_CAR,
  PUBLIC_ROUTE_VNI_VCX_EPASS,
  PUBLIC_ROUTE_BMI_VCX_CAR,
} from "./routeconstants";

const publicRoutes = [
  {
    path: "/v2/tnds-xemay",
    exact: true,
    component: lazy(() => import("views/core-tnds-xemay/index")),
  },
  {
    path: PUBLIC_ROUTE_TNDS.MOTORBIKE,
    exact: true,
    component: lazy(() =>
      import("views/pti/tnds_grab/components/tndsxm/index")
    ),
  },
  // {
  //   path: PUBLIC_ROUTE_TNDS.CAR_MIC_VCX,
  //   exact: true,
  //   component: lazy(() => import("views/mic/grab_oto_tnds+vcx/order")),
  // },
  {
    path: PUBLIC_ROUTE_TNDS.CAR_MIC_VCX_RESULT,
    exact: true,
    component: lazy(() => import("views/mic/grab_oto_tnds+vcx/result")),
  },
  {
    path: PUBLIC_ROUTE_TNDS.CAR,
    exact: true,
    component: lazy(() =>
      import("views/pti/core_tnds_grab/components/tndsoto/index")
    ),
  },
  // {
  //   path: PUBLIC_ROUTE_TNDS.GIFT_VUNGTAM,
  //   exact: true,
  //   component: lazy(() => import("views/pti/grab-vungtam/index")),
  // },
  // {
  //   path: PUBLIC_ROUTE_TNDS.VACCINE,
  //   exact: true,
  //   component: lazy(() => import("views/pti/grab-phongve/index")),
  // },
  // {
  //     path: PUBLIC_ROUTE_TNDS.VUNGTAM,
  //     exact: true,
  //     component: lazy(() => import('views/pti/grab-vungtam-mua/index')),
  // },
  // {
  //     path: PUBLIC_ROUTE_TNDS.VUNGTAM_PAYMENT_RESULT,
  //     exact: true,
  //     component: lazy(() => import('views/pti/grab-vungtam-mua/paymentResult')),
  // },
  {
    path: PUBLIC_ROUTE_DOTQUY.BHDOTQUY,
    exact: true,
    component: lazy(() => import("views/lian/bhdotquy/index")),
  },
  {
    path: PUBLIC_ROUTE_PTI_TNDSXEMAY.MAIN,
    exact: true,
    component: lazy(() => import("views/pti/tnds_xemay/maincomponent/index")),
  },
  {
    path: PUBLIC_ROUTE_PTI_TNDSXEMAY.PAYMENT,
    exact: true,
    component: lazy(() => import("views/pti/tnds_xemay/commons/payment")),
  },
  {
    path: PUBLIC_ROUTE_PTI_TNDSOTO.MAIN,
    exact: true,
    component: lazy(() => import("views/pti/tnds_oto/maincomponent/index")),
  },
  {
    path: PUBLIC_ROUTE_PTI_TNDSOTO.PAYMENT,
    exact: true,
    component: lazy(() => import("views/pti/tnds_oto/commons/payment")),
  },
  {
    path: PUBLIC_ROUTE_PTI_VATCHATXEMAY.MAIN,
    exact: true,
    component: lazy(() => import("views/pti/vatchatxemay/components/index")),
    // component:lazy(() => import('views/commom_component/nullView')),
  },
  {
    path: PUBLIC_ROUTE_PTI_VATCHATXEMAY.PAYMENT,
    exact: true,
    component: lazy(() => import("views/pti/vatchatxemay/components/payment")),
  },
  {
    path: PUBLIC_ROUTE_PTI_BENH_HIEM_NGHEO.MAIN,
    exact: true,
    component: lazy(() => import("views/pti/bh_benh_hiem_ngheo")),
  },
  {
    path: PUBLIC_ROUTE_PTI_BHSK.MAIN,
    exact: true,
    component: lazy(() => import("views/pti/bhsk/View")),
  },
  {
    path: PUBLIC_ROUTE_LIAN_BHTRUCOT.MAIN,
    exact: true,
    component: lazy(() => import("views/lian/bh_trucot")),
  },
  {
    path: PUBLIC_ROUTE_PVI_HOME_CARE.MAIN,
    exact: true,
    component: lazy(() => import("views/pvi/home-care")),
  },
  {
    path: PUBLIC_ROUTE_PTI_VUNG_TAM_AN.MAIN,
    exact: true,
    component: lazy(() => import("views/pti/vung_tam_an/components/index")),
  },
  {
    path: PUBLIC_ROUTE_PVI_TRU_COT.MAIN,
    exact: true,
    component: lazy(() => import("views/pvi/bh-trucot")),
  },
  {
    path: PUBLIC_ROUTE_PAGE_ALL_PRODUCTS.MAIN,
    exact: true,
    component: lazy(() => import("views/page_all_product")),
  },
  {
    path: PUBLIC_ROUTE_PTI_FINA_BHSUCKHOE.MAIN,
    exact: true,
    component: lazy(() => import("views/pti/fina_bhsuckhoe")),
  },
  {
    path: PUBLIC_ROUTE_TNDS.MOTORBIKE_PVI,
    exact: true,
    component: lazy(() =>
      import("views/pvi/tnds_grab/components/tndsxm/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_TNDS.CAR_PVI,
    exact: true,
    component: lazy(() =>
      import("views/pvi/tnds_grab/components/tndsoto/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_TNDS.CAR_VNI,
    exact: true,
    component: lazy(() =>
      import("views/vni/tnds_grab_v2/components/tndsoto/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_TNDS.MOTORBIKE_PTI,
    exact: true,
    component: lazy(() =>
      import("views/pti/core_tnds_grab/components/tndsxm/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_TNDS.CAR_PTI,
    exact: true,
    component: lazy(() =>
      import("views/pti/core_tnds_grab/components/tndsoto/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_PVI_TRO_CAP_NAM_VIEN.MAIN,
    exact: true,
    component: lazy(() => import("views/pvi/tro-cap-nam-vien")),
  },
  {
    path: PUBLIC_ROUTE_PVI_TAI_NAN_CA_NHAN.MAIN,
    exact: true,
    component: lazy(() => import("views/pvi/bh-tai-nan-canhan")),
  },
  {
    path: PUBLIC_ROUTE_PVI_TRO_CAP_NAM_VIEN.MAIN,
    exact: true,
    component: lazy(() => import("views/pvi/tro-cap-nam-vien")),
  },
  {
    path: PUBLIC_ROUTE_MIC_TNDSXEMAY.MAIN,
    exact: true,
    component: lazy(() => import("views/mic/tnds_xemay/maincomponent/index")),
  },
  {
    path: PUBLIC_ROUTE_TNDSXEMAY.MAIN,
    exact: true,
    component: lazy(() =>
      import("views/multiins/tnds_xemay/maincomponent/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_SALE_TNDS_VCX_CAR.MAIN,
    exact: true,
    component: lazy(() => import("views/multiins/sale_oto_tnds+vcx/order")),
  },
  {
    path: PUBLIC_ROUTE_SALE_TNDS_VCX_CAR_RESULT.MAIN,
    exact: true,
    component: lazy(() => import("views/multiins/sale_oto_tnds+vcx/result")),
  },
  {
    path: PUBLIC_ROUTE_TNDS_VCX_CAR.MAIN,
    exact: true,
    component: lazy(() => import("./../../views/multiins/oto_tnds+vcx/order")),
  },
  {
    path: PUBLIC_ROUTE_MIC_TNDS_VCX_CAR.MAIN,
    exact: true,
    component: lazy(() => import("views/mic/oto_tnds+vcx/order")),
  },
  {
    path: PUBLIC_ROUTE_SELLY_MIC_TNDSXEMAY.MAIN,
    exact: true,
    component: lazy(() => import("views/selly/tnds_xemay/maincomponent/index")),
  },
  {
    path: PUBLIC_ROUTE_SELLY_MIC_TNDSXEMAY_ALL.MAIN,
    exact: true,
    component: lazy(() =>
      import("views/selly/tnds_xemay_all/maincomponent/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_MB_BANK_DOWNNLOAD_APP.MAIN,
    exact: true,
    component: lazy(() => import("views/mb_bank/download_app")),
  },
  {
    path: PUBLIC_ROUTE_GRAB_PTI_VUNG_TAM_AN.MAIN,
    exact: true,
    component: lazy(() => import("views/pti/grab_vung-tam-an")),
  },
  {
    path: PUBLIC_ROUTE_TNDS.VUNGTAMAN_PAYMENT_RESULT,
    exact: true,
    component: lazy(() => import("views/pti/grab_vung-tam-an/paymentResult")),
  },
  {
    path: PUBLIC_ROUTE_MIC_BAO_AN_TINH_DUNG.MAIN,
    exact: true,
    component: lazy(() => import("views/mic/bh-bao-an-tinh-dung")),
  },
  {
    path: PUBLIC_ROUTE_REGIST_ACC_GSALE.MAIN,
    exact: true,
    component: lazy(() => import("views/gsale/register/component")),
  },
  {
    path: PUBLIC_ROUTE_RENEWALS_ORDER_INFO.MAIN,
    exact: true,
    component: lazy(() => import("views/renewals/order-info")),
  },
  {
    path: PUBLIC_ROUTE_RENEWALS_ORDER_RESULT.MAIN,
    exact: true,
    component: lazy(() => import("views/renewals/order-result")),
  },
  {
    path: PUBLIC_ROUTE_MIC_TNDSXEMAY.LOADING_PAYMENT,
    exact: true,
    component: lazy(() =>
      import("views/mic/tnds_xemay/maincomponent/PaymentResult")
    ),
  },
  {
    path: PUBLIC_ROUTE_PTI_TNDSXEMAY.PAYMENT_RESULT,
    exact: true,
    component: lazy(() =>
      import("views/pti/tnds_xemay/maincomponent/PaymentResult")
    ),
  },
  {
    path: PUBLIC_ROUTE_PTI_TNDSOTO.PAYMENT_RESULT,
    exact: true,
    component: lazy(() =>
      import("views/pti/tnds_oto/maincomponent/PaymentResult")
    ),
  },
  {
    path: PUBLIC_ROUTE_PTI_VATCHATXEMAY.PAYMENT_RESULT,
    exact: true,
    component: lazy(() =>
      import("views/pti/vatchatxemay/components/PaymentResult")
    ),
  },
  {
    path: PUBLIC_ROUTE_PVI_TAI_NAN_CA_NHAN.PAYMENT_RESULT,
    exact: true,
    component: lazy(() => import("views/pvi/bh-tai-nan-canhan/PaymentResult")),
  },
  {
    path: PUBLIC_ROUTE_DOTQUY.PAYMENT_RESULT,
    exact: true,
    component: lazy(() => import("views/lian/bhdotquy/PaymentResult")),
  },
  {
    path: PUBLIC_ROUTE_REBUILD_WEB_BYPRODUCTID.MAIN,
    exact: true,
    component: lazy(() => import("views/commom_component/RebuildPage")),
  },
  {
    path: PUBLIC_ROUTE_TNDS.CAR_PTI_TNDS_VA_VCX,
    exact: true,
    component: lazy(() => import("../../views/pti/grab_oto_tnds+vcx/order")),
  },
  {
    path: PUBLIC_ROUTE_TNDS.CAR_PTI_TNDS_VA_VCX_RESULT,
    exact: true,
    component: lazy(() => import("../../views/pti/grab_oto_tnds+vcx/result")),
  },
  {
    path: RETRACK_LOADING.MAIN,
    exact: true,
    component: lazy(() => import("../../views/rentrack-view/loading")),
  },
  {
    path: PUBLIC_ROUTE_TOMATO_COMBO.MAIN,
    exact: true,
    component: lazy(() => import("../../views/tomato/index")),
  },
  {
    path: PUBLIC_ROUTE_TOMATO_COMBO.PAYMENT_DONE,
    exact: true,
    component: lazy(() => import("../../views/tomato/page/paymentDone")),
  },
  {
    path: PUBLIC_ROUTE_TOMATO_COMBO.ACTIVE,
    exact: true,
    component: lazy(() => import("../../views/tomato/page/active")),
  },
  {
    path: PUBLIC_ROUTE_TOMATO_COMBO.ACTIVE_RESULT,
    exact: true,
    component: lazy(() => import("../../views/tomato/page/result")),
  },
  {
    path: PUBLIC_ROUTE_EASYCARE.MAIN,
    exact: true,
    component: lazy(() => import("../../views/lian/easy-care/index")),
  },
  {
    path: PUBLIC_ROUTE_PHUCBINHAN.MAIN,
    exact: true,
    component: lazy(() => import("../../views/pvi/bh-phuc-binh-an/index")),
  },
  {
    path: PUBLIC_ROUTE_PTI_TNDS_OTOV2.MAIN,
    exact: true,
    component: lazy(() => import("../../views/pti/tnds_otov2/forms/introduce")),
  },
  {
    path: PUBLIC_ROUTE_PTI_EPASS_TNDSOTO.MAIN,
    exact: true,
    component: lazy(() =>
      import("../../views/pti/epass-tnds-oto/maincomponent/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_VNI_EPASS_TNDSOTO.MAIN,
    exact: true,
    component: lazy(() =>
      import("../../views/vni/epass-tnds-oto/maincomponent/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_PAGE_ALL_PRODUCTS_EPASS.MAIN,
    exact: true,
    component: lazy(() => import("views/all-product-page-epass")),
  },
  {
    path: PUBLIC_ROUTE_PAGE_TRAVEL.MAIN,
    exact: true,
    component: lazy(() =>
      import("views/mic/travel_insurance/main_component/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_PAGE_TRAVEL.GROUP,
    exact: true,
    component: lazy(() =>
      import("views/mic/travel_insurance/travel_group/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_PAGE_FAMILY_CARE.MAIN,
    exact: true,
    component: lazy(() =>
      import("../../views/lian/family-care-insurance/main")
    ),
  },
  {
    path: PUBLIC_ROUTE_PVI_BENHHIEMNGHEO.MAIN,
    exact: true,
    component: lazy(() => import("views/pvi/bh-benh-hiem-ngheo")),
  },
  {
    path: PUBLIC_ROUTE_VNI_TNDSOTO.MAIN,
    exact: true,
    component: lazy(() => import("views/vni/tnds_oto/maincomponent/index")),
  },
  ,
  {
    path: PUBLIC_ROUTE_TOMATO_GSALE.MAIN,
    exact: true,
    component: lazy(() => import("views/tomato/tomato-gsale/index")),
  },
  {
    path: PUBLIC_ROUTE_MIC_BUSINESS_TRAVEL.MAIN,
    exact: true,
    component: lazy(() => import("views/mic/business-travel/index")),
  },
  {
    path: PUBLIC_ROUTE_BAO_HIEM_Y_TE.MAIN,
    exact: true,
    component: lazy(() => import("views/bao-hiem-y-te/index")),
  },
  {
    path: PUBLIC_ROUTE_LUCKY_SPIN.MAIN,
    exact: true,
    component: lazy(() => import("views/lucky-spin/index")),
  },
  {
    path: PUBLIC_ROUTE_LUCKY_SPIN.EMPLOYEE,
    exact: true,
    component: lazy(() => import("views/lucky-spin/index")),
  },
  // {
  //   path: PUBLIC_ROUTE_LALAMOVE_V2_TNDS_XEMAY.MAIN,
  //   exact: true,
  //   component: lazy(() => import("views/lalamove/core-tnds-xemay/index")),
  // },
  // {
  //   path: PUBLIC_ROUTE_LALAMOVE_V2_TNDS_OTO.MAIN,
  //   exact: true,
  //   component: lazy(() => import("views/lalamove/tnds_otov2/forms/introduce")),
  // },
  {
    path: PUBLIC_ROUTE_EPASS_V2_TNDS_XEMAY.MAIN,
    exact: true,
    component: lazy(() => import("views/epass/core-tnds-xemay/index")),
  },
  {
    path: PUBLIC_ROUTE_EPASS_V2_TNDS_OTO.MAIN,
    exact: true,
    component: lazy(() => import("views/epass/tnds_otov2/forms/introduce")),
  },
  {
    path: PUBLIC_ROUTE_LALAMOVE_PTI_VATCHATXEMAY.MAIN,
    exact: true,
    component: lazy(() =>
      import("views/lalamove/vatchatxemay/components/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_TNDS.MOTORBIKE_PVI_V2,
    exact: true,
    component: lazy(() =>
      import("views/pvi/tnds_grab_v2/components/tndsxm/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_TNDS.CAR_PVI_V2,
    exact: true,
    component: lazy(() =>
      import("views/pvi/tnds_grab_v2/components/tndsoto/index")
    ),
  },
  {
    path: PUBLIC_ROUTE_BM_CARE.MAIN,
    exact: true,
    component: lazy(() => import("views/gic/bm-care/index")),
  },
  {
    path: PUBLIC_ROUTE_MASTER_RIDER.MAIN,
    exact: true,
    component: lazy(() => import("views/master-rider/main/index")),
  },
  // {
  //   path: PUBLIC_ROUTE_REFUND_COVER.MAIN,
  //   exact: true,
  //   component: lazy(() => import("views/refund-cover/index")),
  // },
  // {
  //   path: PUBLIC_ROUTE_V2_BAO_HIEM_Y_TE.MAIN,
  //   exact: true,
  //   component: lazy(() => import("views/bao-hiem-y-te-v2")),
  // },
  {
    path: PUBLIC_ROUTE_VNI_VCX_CAR.MAIN,
    exact: true,
    component: lazy(() => import("views/vni/oto_vcx/order")),
  },
  {
    path: PUBLIC_ROUTE_VNI_VCX_EPASS.MAIN,
    exact: true,
    component: lazy(() => import("views/vni/epass_oto_vcx/order")),
  },
];

export default function Routes() {
  return (
    <Suspense fallback={<div></div>}>
      <Router>
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component />
            </Route>
          ))}
        </Switch>
      </Router>
    </Suspense>
  );
}
